import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="linkbio__title"> Danh sachs link cua ben minh </div>

      <div className="linkbio__items-container">
        <a
          className="linkbio__items-link"
          href="https://www.chillwithme.online/"
          target=""
        >
          <div className="linkbio__items border">
            <span className="linkbio__items-icon image__internet"></span>
            <p> Website </p>
          </div>
        </a>
      </div> 
      
      {/* FACEBOOK LINK */}
      <div className="linkbio__items-container">
        <a
          className="linkbio__items-link"
          href="https://www.facebook.com/mitupa.tattoo.painting"
          target=""
        >
          <div className="linkbio__items border">
            <span className="linkbio__items-icon image__fb"></span>
            <p>Page Facebook</p>
          </div>
        </a>
      </div>
      
      {/* INSTAGRAM LINK */}
      <div className="linkbio__items-container">
        <a
          className="linkbio__items-link"
          href="https://www.instagram.com/mitupatattoo/"
          target=""
        >
          <div className="linkbio__items border">
            <span className="linkbio__items-icon image__ig"></span>
            <p>Instagram </p>
          </div>
        </a>
      </div>

      {/* FACEBOOK LINK */}

      {/* ZALO LINK */}
      <div className="linkbio__items-container">
        <a
          className="linkbio__items-link"
          href="https://www.facebook.com/profile.php?id=61553744483256&mibextid=LQQJ4d"
          target=""
        >
          <div className="linkbio__items border">
            <span className="linkbio__items-icon image__zalo"></span>
            <p> Lớp dạy nghề </p>
          </div>
        </a>
      </div>

      <div className="linkbio__items-container">
        <a
          className="linkbio__items-link"
          href="https://www.instagram.com/tattoo_training_course_mitupa?igsh=dmN2eGw1MXpubW0w"
          target=""
        >
          <div className="linkbio__items border">
            <span className="linkbio__items-icon image__shopee"></span>
            <p> Ig lớp dạy nghề </p>
          </div>
        </a>
      </div>  
 
    </div>
  );
}

export default App;
